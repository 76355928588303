export const TAGS = [
    "Boom Bap",
    "Baton Rouge",
    "Detroit",
    "Drill",
    "Glo",
    "Hyperpop",
    "Jersey",
    "Pain",
    "Pluggnb",
    "R&B",
    "Rage",
    "Reggaeton",
    "Rock",
    "Soul",
    "Supertrap",
    "Trap",
    "West Coast"
]